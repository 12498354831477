<template>
  <div class="animated fadeIn">
    <b-card-group class="mb-4">
      <b-card
        header-tag="header"
        footer-tag="footer"
        class="col-md-8 p-0 mr-2">
        <div slot="header">
          <strong> Filtros </strong>
        </div>
        <div class="p-1 mt-2">
          <b-row class="mb-1">
            <b-col sm="12" md="4" class="d-md-block">
              <b-button-toolbar class="float-left">
                <b-form-radio-group class="mr-3" buttons button-variant="outline-primary" v-model="filtro.fields.periodo" @change="consultaPeriodo" name="radiosBtn">
                  <b-form-radio class="mx-0" value="Diaria">Diária</b-form-radio>
                  <b-form-radio class="mx-0" value="Semanal">Semanal</b-form-radio>
                  <b-form-radio class="mx-0" value="Mensal">Mensal</b-form-radio>
                </b-form-radio-group>
              </b-button-toolbar>
            </b-col>
            <b-col sm="12" md="8">
              <div class="custom-control custom-checkbox pt-1">
                <input type="checkbox" class="custom-control-input" id="cbConsiderarOutrasLojas" :disabled="filtro.fields.centres === 0" v-model="filtro.fields.considerarOutrasLojas">
                <label class="custom-control-label" for="cbConsiderarOutrasLojas">Considerar vendas de outras lojas</label>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col :sm="filtro.fields.periodo === 'Diaria' ? 4 : 3" class="pr-md-0 mb-1 mb-md-0">
              <b-form-select :plain="true" v-model="filtro.fields.gestao" :options="filtro.listas.gestao" @change="filterLojas"></b-form-select>
            </b-col>
            <b-col :sm="filtro.fields.periodo === 'Diaria' ? 4 : 3" class="pr-md-0 mb-1 mb-md-0">
              <b-form-select :plain="true" v-model="filtro.fields.centres" :options="filtro.listas.centresFiltradosPorGestao" @change="filterOutrasLojas"></b-form-select>
            </b-col>
            <b-col sm="4" v-if="filtro.fields.periodo === 'Diaria'">
              <b-form-input :plain="true" v-model="filtro.fields.dataDia" type="date"></b-form-input>
            </b-col>
            <b-col sm="3" v-if="filtro.fields.periodo !== 'Diaria'" class="pr-md-0 mb-1 mb-md-0">
              <b-form-select :plain="true" v-model="filtro.fields.mes" :options="filtro.listas.meses" @change="setIntervalo"></b-form-select>
            </b-col>
            <b-col sm="3" v-if="filtro.fields.periodo !== 'Diaria'">
              <b-form-select :plain="true" v-model="filtro.fields.ano" :options="filtro.listas.anos" @change="setIntervalo"></b-form-select>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col :sm="filtro.fields.periodo === 'Diaria' ? 8 : 6" class="pr-md-0">
              <b-form-select :plain="true" v-model="filtro.fields.intervalo" :options="filtro.listas.intervalo" :disabled="filtro.fields.periodo !== 'Semanal'" @change="setDatasIntervalo"></b-form-select>
            </b-col>
            <b-col sm="1">
              <b-button variant="primary" class="my-2 my-sm-0 text-white" :class="{'btn-block': $mq === 'sm'}" @click="search()">
                <i class="fa fa-search"></i>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <b-card
        header-tag="header"
        footer-tag="footer"
        class="col-md-4 p-0">
        <div slot="header">
          <strong> Layout </strong>
        </div>
        <div class="p-1">
          <b-row class="mb-1">
            <b-col sm="12" class="d-md-block">
              <b-button-toolbar class="float-left">
                <b-form-radio-group class="mr-3" buttons button-variant="outline-primary" v-model="filtro.fields.exibicao" name="radiosBtn">
                  <b-form-radio class="mx-0" value="Graficos">Gráficos</b-form-radio>
                  <b-form-radio class="mx-0" value="Tabelas">Tabelas</b-form-radio>
                </b-form-radio-group>
              </b-button-toolbar>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col sm="12">
              <b-form-select :plain="true" v-model="filtro.fields.agrupamento" :options="filtro.listas.agrupamentos" :disabled="filtro.fields.centres !== 0" @change="search"></b-form-select>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card-group>

    <div v-if="filtro.fields.exibicao === 'Tabelas'">
      <b-row v-for="(item, index) in items" :key="index">
        <b-col cols="12" sm="12" lg="12">
          <b-card header-tag="header">
            <div slot="header">
              <strong>{{ item.name }}</strong>
            </div>
            <h6 class="text-primary"> - Vendas</h6>
            <b-table striped small responsive="md" :items="item.records" :fields="fields.records">
              <template slot="meta" slot-scope="data">
                <i class="fa fa-circle fa-lg" :class="getMeta(data.item.meta)"></i>
              </template>
              <template slot="vendas" slot-scope="data">
                <span>{{ data.item.vendas | currency({symbol: ''}) }}</span>
              </template>
              <template slot="bronze" slot-scope="data">
                <span>{{ data.item.bronze / data.item.qtd_funcionarios | currency({symbol: ''}) }}</span>
              </template>
              <template slot="prata" slot-scope="data">
                <span>{{ data.item.prata / data.item.qtd_funcionarios | currency({symbol: ''}) }}</span>
              </template>
              <template slot="ouro" slot-scope="data">
                <span>{{ data.item.ouro / data.item.qtd_funcionarios | currency({symbol: ''}) }}</span>
              </template>
              <template slot="diamante" slot-scope="data">
                <span>{{ data.item.diamante / data.item.qtd_funcionarios | currency({symbol: ''}) }}</span>
              </template>
            </b-table>
            <h6 class="text-primary"> - Outros Indicadores</h6>
            <b-table striped small responsive="md" :items="item.indicadores" :fields="fields.indicadores" foot-clone>
              <template slot="pecas" slot-scope="data">
                {{ data.item.pecas | number }}
                <i class="fa" :class="[
                  data.item.pecas_arrow,
                  data.item.pecas_arrow === 'fa-minus' ? 'text-warning' : (data.item.pecas_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]" />
              </template>
              <template slot="atendimentos" slot-scope="data">
                {{ data.item.atendimentos | number }}
                <i class="fa" :class="[
                  data.item.atendimentos_arrow,
                  data.item.atendimentos_arrow === 'fa-minus' ? 'text-warning' : (data.item.atendimentos_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]" />
              </template>
              <template slot="vp" slot-scope="data">
                {{ data.item.vp | currency({symbol: ''}) }}
                <i class="fa" :class="[
                  data.item.vp_arrow,
                  data.item.vp_arrow === 'fa-minus' ? 'text-warning' : (data.item.vp_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]" />
              </template>
              <template slot="va" slot-scope="data">
                {{ data.item.va | currency({symbol: ''}) }}
                <i class="fa" :class="[
                  data.item.va_arrow,
                  data.item.va_arrow === 'fa-minus' ? 'text-warning' : (data.item.va_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]" />
              </template>
              <template slot="pa" slot-scope="data">
                {{ data.item.pa | number }}
                <i class="fa" :class="[
                  data.item.pa_arrow,
                  data.item.pa_arrow === 'fa-minus' ? 'text-warning' : (data.item.pa_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]" />
              </template>
              <template slot="clientes" slot-scope="data">
                {{ data.item.clientes | number }}
                <i class="fa" :class="[
                  data.item.clientes_arrow,
                  data.item.clientes_arrow === 'fa-minus' ? 'text-warning' : (data.item.clientes_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]" />
              </template>
              <template slot="conversao" slot-scope="data">
                {{ data.item.conversao | number }}
                <i class="fa" :class="[
                  data.item.conversao_arrow,
                  data.item.conversao_arrow === 'fa-minus' ? 'text-warning' : (data.item.conversao_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]" />
              </template>
              <template slot="descontos" slot-scope="data">
                {{ data.item.descontos | currency({symbol: ''}) }}
                <i class="fa" :class="[
                  data.item.descontos_arrow,
                  data.item.descontos_arrow === 'fa-minus' ? 'text-warning' : (data.item.descontos_arrow === 'fa-arrow-up' ? 'text-success' : 'text-danger')
                ]" />
              </template>

              <template slot="FOOT_descricao">
                Média da {{ item.name }}
              </template>
              <template slot="FOOT_pecas">
                {{ item.media.pecas | number }}
              </template>
              <template slot="FOOT_atendimentos">
                {{ item.media.atendimentos | number }}
              </template>
              <template slot="FOOT_vp">
                {{ item.media.vp | currency({symbol: ''}) }}
              </template>
              <template slot="FOOT_va">
                {{ item.media.va | currency({symbol: ''}) }}
              </template>
              <template slot="FOOT_pa">
                {{ item.media.pa | number }}
              </template>
              <template slot="FOOT_conversao">
                {{ item.media.conversao | number }}
              </template>
              <template slot="FOOT_clientes">
                {{ item.media.clientes | number }}
              </template>
              <template slot="FOOT_descontos">
                {{ item.media.descontos | currency({symbol: ''}) }}
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <div v-if="filtro.fields.exibicao === 'Graficos'">
      <b-row v-if="exibirMetaAtingida">
        <b-col cols="12" sm="12" lg="4">
          <b-card :no-body="true">
            <b-card-body class="clearfix">
              <i class="icon-up p-3 font-2xl mr-3 float-left float-up" :class="`meta-${metaAtingida.icon || 'nao-atingida'}`"></i>
              <div class="text-right mb-0 h5">Meta Atingida</div>
              <div class="ml-auto text-center text-muted mt-5">
                <h2 class="ml-auto font-weight-bold">{{ valorVendas | currency }}</h2>
              </div>
              <div class="ml-auto text-center mb-5" :class="metaAtingida.diferenca < 0 ? 'text-danger' : 'text-success'">
                <h5>{{ metaAtingida.diferenca < 0 ? 'Falta' : 'Passou' }} {{ Math.abs(metaAtingida.diferenca) | currency }}</h5>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12" sm="12" lg="8">
          <b-card :no-body="true">
            <b-card-body class="clearfix">
              <i class="icon-up vendas p-3 font-2xl mr-3 float-left float-up"></i>
              <div class="text-right mb-0 h5">Metas</div>
              <b-row class="mt-4">
                <b-col cols="12" sm="6" lg="6">
                  <div class="d-flex">
                    <gauge :chartdata="charts.MetaAtingida" class="w-100" :height="134" chartId="chart-gauge-01" v-if="loaded" />
                  </div>
                </b-col>

                <b-col cols="12" sm="6" lg="6" class="pt-4">
                  <div class="d-flex">
                    <span class="text-muted">
                      <span class="badge badge-meta-bronze" style="vertical-align: middle; margin-bottom: 2px;">&nbsp;&nbsp;</span> Meta Bronze
                    </span>
                    <span class="ml-auto">{{ valorMetas.bronze | currency }}</span>
                  </div>
                  <div class="d-flex">
                    <span class="text-muted">
                      <span class="badge badge-meta-prata" style="vertical-align: middle; margin-bottom: 2px;">&nbsp;&nbsp;</span> Meta Prata
                    </span>
                    <span class="ml-auto">{{ valorMetas.prata | currency }}</span>
                  </div>
                  <div class="d-flex">
                    <span class="text-muted">
                      <span class="badge badge-meta-ouro" style="vertical-align: middle; margin-bottom: 2px;">&nbsp;&nbsp;</span> Meta Ouro
                    </span>
                    <span class="ml-auto">{{ valorMetas.ouro | currency }}</span>
                  </div>
                  <div class="d-flex">
                    <span class="text-muted">
                      <span class="badge badge-meta-diamante" style="vertical-align: middle; margin-bottom: 2px;">&nbsp;&nbsp;</span> Meta Diamante
                    </span>
                    <span class="ml-auto">{{ valorMetas.diamante | currency }}</span>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-card v-for="(item, index) in items" :key="index">
        <div slot="header" class="font-weight-bold">{{ item.name }}</div>
        <horizontal-bar :chartdata="item.charts.Gestoes" :displayLegend="false" :moneyFormat="true" :height="(item.charts.Gestoes.datasets[0].data.length + 10) * 10" chartId="chart-bar-01" v-if="loaded && displayChart(item, 'Gestoes')" />
        <div class="alert alert-secondary text-center mb-0" v-if="!displayChart(item, 'Gestoes')">
          Nenhum resultado encontrado.
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import leftPad from 'left-pad';
import session from '@/shared/session'
import { mes } from '@/shared/string'
import API from '@/core/api';
import cTable from '@/core/components/Table.vue'
import HorizontalBar from '@/core/components/charts/HorizontalBar';
import Pie from '@/core/components/charts/Pie';
import Gauge from '@/core/components/charts/Gauge';
import { setInterval } from 'timers';
import lojasPorGestaoUniMixin from '@/core/mixins/lojasPorGestaoUni.mixin.js';

export default {
  name: 'Vendas',
  components: {
    cTable,
    HorizontalBar,
    Pie,
    Gauge
  },
  mixins:[
    lojasPorGestaoUniMixin
  ],
  data: () => {
    return {
      loaded: false,
      filtro: {
        listas: {
          gestao: session.get('gestoes'),
          centres: session.get('lojas'),
          centresDefault: session.get('lojas'),
          formaVenda: session.get('formas'),
          agrupamentos: [{value: 1, text: 'Gestão/Loja'}, {value: 2, text: 'Gestão/Vendedor'}, {value: 3, text: 'Grupo Empresarial/Loja'}, {value: 4, text: 'Grupo Empresarial/Vendedor'}],
          meses: [...Array(12).keys()].map(a => { return { value: a+1, text: mes(a+1) } }),
          anos: [],
          intervalo: [],
          centresFiltradosPorGestao: []
        },
        fields: {
          periodo: 'Diaria',
          gestao: 0,
          centres: 0,
          dataDia: moment().format('YYYY-MM-DD'),
          dataIni: moment().format('YYYY-MM-DD'),
          dataFim: moment().format('YYYY-MM-DD'),
          mes: moment().format('M'),
          ano: moment().format('YYYY'),
          intervalo: {},
          exibicao: 'Graficos',
          agrupamento: 1,
          considerarOutrasLojas: false
        }
      },
      items: [],
      fields: {
        records: [
          {key: 'descricao', label: 'Descrição', sortable: true},
          {key: 'vendas', label: 'Vendas R$', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'meta', label: 'Meta Atingida', class: 'text-center'},
          {key: 'bronze', label: 'Bronze', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'prata', label: 'Prata', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'ouro', label: 'Ouro', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'diamante', label: 'Diamante', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'}
        ],
        indicadores: [
          {key: 'descricao', label: 'Descrição', sortable: true},
          {key: 'pecas', label: 'Peças', sortable: true, numeric: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'atendimentos', label: 'Atend.', sortable: true, numeric: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'vp', label: 'V/P R$', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'va', label: 'V/A R$', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'pa', label: 'P/A', sortable: true, numeric: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'clientes', label: 'Clientes', sortable: true, numeric: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'conversao', label: 'Tx. Conv. %', sortable: true, numeric: true, thClass: 'text-right', tdClass: 'text-right'},
          {key: 'descontos', label: 'Descontos R$', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'}
        ],
        media: [
          {key: 'pecas', thStyle: 'display: none'},
          {key: 'atendimentos', thStyle: 'display: none'},
          {key: 'vp', thStyle: 'display: none'},
          {key: 'va', thStyle: 'display: none'},
          {key: 'pa', thStyle: 'display: none'},
          {key: 'clientes', thStyle: 'display: none'},
          {key: 'conversao', thStyle: 'display: none'},
          {key: 'descontos', thStyle: 'display: none'}
        ]
      },
      isDisabledDataFim: true,
      exibirMetaAtingida: false,
      charts: {},
      metas: [],
      corMetas: {
        bronze: 'rgb(153, 50, 0)',
        prata: 'rgb(156, 156, 156)',
        ouro: 'rgb(248, 203, 0)',
        diamante: 'rgb(32, 168, 216)'
      },
      valores: {
        vendas: 0,
        metas: {
          bronze: 0,
          prata: 0,
          ouro: 0,
          diamante: 0,
          qtdFunc: 0
        },
        grafico: {
          valores: {},
          cores: {}
        },
        metaAtingida: {
          icon: '',
          valor: 0,
          diferenca: 0
        }
      },
      listPeriodos: []
    }
  },
  computed: {
    valorVendas: function() {
      return this.valores.vendas;
    },
    valorMetas: function() {
      return this.valores.metas;
    },
    metaAtingida: function() {
      return this.valores.metaAtingida;
    }
  },
  methods: {
    async search() {
      try {
        if (this.validaPeriodo() === true) {
          this.exibirMetaAtingida = false;
          this.loaded = null;

          const metasPorLojaAPI = new API('api/metas-por-loja');
          const metas = await metasPorLojaAPI.get({ ...this.parseFilters(this.filtro.fields) });
          this.metas = metas.data;
          if (this.metas.length === 0) {
            swal({
              title: 'Informação',
              text: 'Nenhuma meta encontrada para a(s) loja(s) selecionada(s).',
              icon: 'info',
              dangerMode: true
            });
          }

          const metasAPI = new API('api/metas');
          const {data} = await metasAPI.get({ ...this.parseFilters(this.filtro.fields) });
          this.definirMeta(data);
          this.items = data;

          if (this.filtro.fields.centres !== 0) {
            this.exibirMetaAtingida = true;
          }

          const { centres, agrupamento } = this.filtro.fields
          if (centres === 0) {
            this.aplicarAgrupamento();
            if ([2, 4].includes(agrupamento)) {
              this.indicadorMedia(this.items)
            }
          }
          this.loadCharts();
        }
      } catch (e) {
        console.error(e)
      }
    },
    aplicarAgrupamento() {
      const { agrupamento } = this.filtro.fields

      if ([1, 2].includes(agrupamento)) {
        let group = []
        let gestoes = this.filtro.listas.gestao.filter(g => g.value && g.value !== 0)
        let _group = _.groupBy(this.items[0].records, 'gestao')
        gestoes.forEach(g => {
          if (_group[g.value]) {
            const indicadores = this.items[0].indicadores.filter(i => Number(i.gestao) === g.value)
            let media = {
              ...indicadores.reduce((prev, curr) => {
                return {
                  pecas: Number(prev.pecas || 0) + Number(curr.pecas || 0),
                  atendimentos: Number(prev.atendimentos || 0) + Number(curr.atendimentos || 0),
                  vp: Number(prev.vp || 0) + Number(curr.vp || 0),
                  va: Number(prev.va || 0) + Number(curr.va || 0),
                  pa: Number(prev.pa || 0) + Number(curr.pa || 0),
                  clientes: Number(prev.clientes || 0) + Number(curr.clientes || 0),
                  conversao: Number(prev.conversao || 0) + Number(curr.taxa_conversao || 0),
                  descontos: Number(prev.descontos || 0) + Number(curr.descontos || 0),
                }
              }, {})
            }
            for (const m in media) {
              media[m] = (media[m] / indicadores.length)
            }
            group.push({
              name: _group[g.value][0].gestao_desc,
              records: [ ..._group[g.value] ],
              indicadores,
              media
            })
          }
        })

        if (group.length > 0) {
          this.items = group
        }
      } else if ([3, 4].includes(agrupamento)) {
        this.items[0].name = 'Grupo Empresarial'
      }
    },
    validaPeriodo() {
      const { periodo, dataDia } = this.filtro.fields;
      if (periodo !== 'Diaria') {
        return true;
      }

      let invalid = false;

      if (dataDia === '' || !moment(dataDia).isValid()) {
        invalid = true;
      }

      if (invalid) {
        return swal({
          text: 'Data inválida.'
        });
      }
      return true
    },
    loadCharts() {
      this.items.forEach(items => {
        this.calcValoresMetas(items);

        const {
          vendas,
          metas,
          metaAtingida
        } = this.valores;

        const newItems = items.records.map(r => {
          const { descricao, vendas, bronze, prata, ouro, diamante, qtd_funcionarios } = r;

          return {
            descricao,
            vendas,
            bronze: Number(bronze / qtd_funcionarios).toFixed(2),
            prata: Number(prata / qtd_funcionarios).toFixed(2),
            ouro: Number(ouro / qtd_funcionarios).toFixed(2),
            diamante: Number(diamante / qtd_funcionarios).toFixed(2)
          }
        })

        items.charts = {
          Gestoes: {
            labels: newItems.map(r => r.descricao).join().split(','),
            datasets: [
              {
                label: 'Vendas',
                backgroundColor: this.getColorMetas(newItems) || '#FF704A',
                data: newItems.map(r => r.vendas).join().split(',')
              }
            ]
          }
        }
      })

      const { bronze, prata, ouro, diamante, qtdFunc } = this.valores.metas;
      const { vendas: gVendas, bronze: gBronze, prata: gPrata, ouro: gOuro, diamante: gDiamante } = this.valores.grafico.valores;
      const { vendas: cVendas, bronze: cBronze, prata: cPrata, ouro: cOuro, diamante: cDiamante } = this.valores.grafico.cores;

      this.charts = {
        MetaAtingida: {
          labels: ['Vendas', '(Bronze) Falta', '(Prata) Falta', '(Ouro) Falta', '(Diamante) Falta'],
          datasets: [
            {
              backgroundColor: [
                cVendas, cBronze, cPrata, cOuro, cDiamante
              ],
              data: [
                gVendas, gBronze, gPrata, gOuro, gDiamante
              ]
            }
          ]
        }
      }

      this.loaded = true
    },
    calcValoresMetas(items) {
      const arMetas = ['', 'bronze', 'prata', 'ouro', 'diamante', ''];
      let meta = '';
      let vendas = 0;
      let metas = {
        bronze: 0,
        prata: 0,
        ouro: 0,
        diamante: 0
      }
      let grafico = {
        valores: {
          vendas: 0,
          bronze: 0,
          prata: 0,
          ouro: 0,
          diamante: 0
        },
        cores: {
          vendas: '#FF704A',
          bronze: 'rgb(153, 50, 0, .2)',
          prata: 'rgb(156, 156, 156, .2)',
          ouro: 'rgb(248, 203, 0, .2)',
          diamante: 'rgb(32, 168, 216, .2)'
        }
      }

      if (items.records.length > 0) {
        vendas = items.records.map(r => r.vendas).reduce((total, current) => Number(total) + Number(current));

        metas.bronze = items.records.map(r => r.bronze / r.qtd_funcionarios).reduce((total, current) => Number(total) + Number(current));
        metas.prata = items.records.map(r => r.prata / r.qtd_funcionarios).reduce((total, current) => Number(total) + Number(current));
        metas.ouro = items.records.map(r => r.ouro / r.qtd_funcionarios).reduce((total, current) => Number(total) + Number(current));
        metas.diamante = items.records.map(r => r.diamante / r.qtd_funcionarios).reduce((total, current) => Number(total) + Number(current));

        meta = Number(vendas) < Number(metas.bronze) ? ''
          : Number(vendas) >= Number(metas.bronze) && Number(vendas) < Number(metas.prata) ? 'bronze'
            : Number(vendas) >= Number(metas.prata) && Number(vendas) < Number(metas.ouro) ? 'prata'
              : Number(vendas) >= Number(metas.ouro) && Number(vendas) < Number(metas.diamante) ? 'ouro'
                : 'diamante';

        grafico.valores = {
          vendas: vendas,
          bronze: metas.bronze,
          prata: metas.prata,
          ouro: metas.ouro,
          diamante: metas.diamante
        }

        if (Number(vendas) < Number(metas.bronze)) {
          grafico.valores.bronze = Number(metas.bronze) - Number(vendas)
          grafico.valores.prata = Number(metas.prata) - Number(vendas)
          grafico.valores.ouro = Number(metas.ouro) - Number(vendas)
          grafico.valores.diamante = Number(metas.diamante) - Number(vendas)
        } else if (Number(vendas) >= Number(metas.bronze) && Number(vendas) < Number(metas.prata)) {
          grafico.valores.bronze = 0
          grafico.valores.prata = Number(metas.prata) - Number(vendas)
          grafico.valores.ouro = Number(metas.ouro) - Number(vendas)
          grafico.valores.diamante = Number(metas.diamante) - Number(vendas)
          grafico.cores.vendas = this.corMetas.bronze
        } else if (Number(vendas) >= Number(metas.prata) && Number(vendas) < Number(metas.ouro)) {
          grafico.valores.bronze = 0
          grafico.valores.prata = 0
          grafico.valores.ouro = Number(metas.ouro) - Number(vendas)
          grafico.valores.diamante = Number(metas.diamante) - Number(vendas)
          grafico.cores.vendas = this.corMetas.prata
        } else if (Number(vendas) >= Number(metas.ouro) && Number(vendas) < Number(metas.diamante)) {
          grafico.valores.bronze = 0
          grafico.valores.prata = 0
          grafico.valores.ouro = 0
          grafico.valores.diamante = Number(metas.diamante) - Number(vendas)
          grafico.cores.vendas = this.corMetas.ouro
        } else {
          grafico.valores.bronze = 0
          grafico.valores.prata = 0
          grafico.valores.ouro = 0
          grafico.valores.diamante = 0
          grafico.cores.vendas = this.corMetas.diamante
        }
      }

      this.valores.grafico = grafico;
      this.valores.vendas = vendas;
      this.valores.metas = metas;
      this.valores.metaAtingida = {
        icon: meta,
        diferenca: meta !== '' ? vendas - (metas[meta] || 0) : vendas - metas.bronze
      };
    },
    getColorMetas(records) {
      if (records.length > 0) {
        const vda = records.map(r => {
          return Number(r.vendas) < Number(r.bronze) ? '#FF704A'
            : Number(r.vendas) >= Number(r.bronze) && Number(r.vendas) < Number(r.prata) ? '#993300'
              : Number(r.vendas) >= Number(r.prata) && Number(r.vendas) < Number(r.ouro) ? '#9c9c9c'
                : Number(r.vendas) >= Number(r.ouro) && Number(r.vendas) < Number(r.diamante) ? '#f8cb00'
                  : '#20a8d8';
        });
        return vda
      }
      return null;
    },
    async getAnosPeriodo() {
      const anosMetasAPI = new API('api/anos-periodos');
      const listAnosMetasNps = await anosMetasAPI.get({ ...this.parseFilters(this.filtro.fields) });
      const { data : { listAnosMetas : anos } } = listAnosMetasNps;
      
      if (anos.length > 0) {
        this.filtro.listas.anos = anos.map(d => {
          return {
            value: d.periodos_anuais,
            text: d.periodos_anuais
          }
        });
      }
    },
    async getListPeriodosSemanal() {
      const periodosAPI = new API('api/periodos-metas');
      this.listPeriodos = await periodosAPI.get({ ...this.parseFilters(this.filtro.fields) });
    },
    setDatasIntervalo() {
      const {dataIni, dataFim} = this.filtro.fields.intervalo;
      this.filtro.fields.dataIni = dataIni;
      this.filtro.fields.dataFim = dataFim;
    },
    async setIntervalo() {
      if (this.filtro.fields.periodo === 'Semanal') {
        this.filtro.fields.dataIni = null;
        this.filtro.fields.dataFim = null;
        this.filtro.fields.intervalo = [];
        this.filtro.listas.intervalo = [];
        
        await this.getListPeriodosSemanal()
        if (this.listPeriodos.data.length > 0) {
          this.filtro.listas.intervalo = this.listPeriodos.data;
          const { mes, ano } = this.filtro.fields;

          if (Number(mes) !== moment().get('month')) {
            const find = moment().set('month', leftPad(Number(mes)-1, 2, '0')).set('year', Number(ano))

            for(const i in this.listPeriodos.data) {
              const intervalo = this.listPeriodos.data[i].value;
              const intervaloIni = moment(intervalo.dataIni)
              const intervaloFim = moment(intervalo.dataFim)
              if (find.isBetween(intervaloIni, intervaloFim, 'days', '[]')) {
                this.filtro.fields.intervalo = intervalo;
              }
            }
          } else {
            this.filtro.fields.intervalo = this.listPeriodos.data[0].value
          }

          this.setDatasIntervalo()
        } else {
          swal({
            title: 'Informação',
            text: 'Nenhum intervalo de metas encontrado.',
            icon: 'info',
            dangerMode: true
          });
        }
      } else if (this.filtro.fields.periodo === 'Mensal') {
        const { mes, ano } = this.filtro.fields;
        const data = moment().set('month', leftPad(Number(mes)-1, 2, '0')).set('year', Number(ano));
        this.filtro.fields.dataIni = data.startOf('month').format('YYYY-MM-DD');
        this.filtro.fields.dataFim = data.endOf('month').format('YYYY-MM-DD');
      }
    },
    async consultaPeriodo(periodo) {
      this.filtro.fields.intervalo = [];

      switch(periodo) {
        case 'Diaria': {
          this.filtro.fields.dataIni = moment().format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().format('YYYY-MM-DD');
          break;
        }
        case 'Semanal': {
          await this.getListPeriodosSemanal()
          await this.setIntervalo()
          break;
        }
        case 'Mensal': {
          const { mes, ano } = this.filtro.fields;
          const data = moment().set('month', leftPad(Number(mes)-1, 2, '0')).set('year', Number(ano));
          this.filtro.fields.dataIni = data.startOf('month').format('YYYY-MM-DD');
          this.filtro.fields.dataFim = data.endOf('month').format('YYYY-MM-DD');
          this.filtro.fields.periodo = 'Mensal'
          break;
        }
        default: {
          return;
        }
      }

      this.search();
    },
    definirMeta(data) {
      data.map(loja => {
        return loja.records.map(e => {
          const { centres, agrupamento } = this.filtro.fields
          const porLojaOuVendedor = centres !== 0 || [2, 4].includes(agrupamento) ? 'vendedor' : 'loja';
          const loja = this.metas.find(m => m[porLojaOuVendedor] === e.cod)
          if (!loja) {
            e.meta = null;
          } else {
            const qtdFunc = porLojaOuVendedor === 'vendedor' ? loja.qtd_funcionarios : 1
            e.meta = Number(e.vendas) < Number(loja.meta_a / qtdFunc) ? 0
              : Number(e.vendas) >= Number(loja.meta_a / qtdFunc) && Number(e.vendas) < Number(loja.meta_b / qtdFunc) ? 1
                : Number(e.vendas) >= Number(loja.meta_b / qtdFunc) && Number(e.vendas) < Number(loja.meta_c / qtdFunc) ? 2
                  : Number(e.vendas) >= Number(loja.meta_c / qtdFunc) && Number(e.vendas) < Number(loja.meta_d / qtdFunc) ? 3
                    : 4
          }
          return e;
        });
      });

      this.indicadorMedia(data)
    },
    indicadorMedia(data) {
      data.map(d => {
        d.indicadores.map(i => {
          i['pecas_arrow'] = Number(i.pecas) === d.media.pecas ? 'fa-minus' : (Number(i.pecas) > d.media.pecas ? 'fa-arrow-up' : 'fa-arrow-down')
          i['atendimentos_arrow'] = Number(i.atendimentos) === d.media.atendimentos ? 'fa-minus' : (Number(i.atendimentos) > d.media.atendimentos ? 'fa-arrow-up' : 'fa-arrow-down')
          i['vp_arrow'] = Number(i.vp) === d.media.vp ? 'fa-minus' : (Number(i.vp) > d.media.vp ? 'fa-arrow-up' : 'fa-arrow-down')
          i['va_arrow'] = Number(i.va) === d.media.va ? 'fa-minus' : (Number(i.va) > d.media.va ? 'fa-arrow-up' : 'fa-arrow-down')
          i['pa_arrow'] = Number(i.pa) === d.media.pa ? 'fa-minus' : (Number(i.pa) > d.media.pa ? 'fa-arrow-up' : 'fa-arrow-down')
          i['clientes_arrow'] = Number(i.clientes) === d.media.clientes ? 'fa-minus' : (Number(i.clientes) > d.media.clientes ? 'fa-arrow-up' : 'fa-arrow-down')
          i['conversao_arrow'] = Number(i.conversao) === d.media.conversao ? 'fa-minus' : (Number(i.conversao) > d.media.conversao ? 'fa-arrow-up' : 'fa-arrow-down')
          i['descontos_arrow'] = Number(i.descontos) === d.media.descontos ? 'fa-minus' : (Number(i.descontos) > d.media.descontos ? 'fa-arrow-up' : 'fa-arrow-down')
          return i
        })
        return d
      })
    },
    parseFilters(filters) {
      let filter = { ...filters };
      const {
        gestao,
        centres,
        periodo,
        considerarOutrasLojas
      } = filters;

      if (periodo === 'Diaria') {
        filter.dataIni = filter.dataDia;
        filter.dataFim = filter.dataDia;
      }

      const gestoes = this.filtro.listas.gestao.map(c => c.value).join(',')
      const lojas = this.filtro.listas.centres.map(c => c.value).join(',')

      filter.gestao = gestao === 0 ? gestoes : gestao;
      filter.centres = centres === 0 ? lojas : centres;

      filter.considerarOutrasLojas = considerarOutrasLojas;

      if (typeof filter.centres === 'number') {
        filter.agrupamento = 1
        filter.listaPorVendedor = true
      }
      return filter;
    },
    displayChart(item, tipo) {
      if (item.charts[tipo].datasets) {
        const data = item.charts[tipo].datasets[0].data
        if (data.length === 0) {
          return false
        }
      }
      return true
    },
    getMeta (meta) {
      return meta === 1 ? 'meta-bronze'
        : meta === 2 ? 'meta-prata'
          : meta === 3 ? 'text-warning'
            : meta === 4 ? 'text-info' : 'fa-frown-o'
    },
    filterLojas() {
      const { gestao, centres } = this.filtro.fields;
      const { centresDefault } = this.filtro.listas;
      this.filtro.listas.centres = gestao === 0 ? centresDefault
        : centresDefault.filter(l => l.gestao === gestao || l.value === 0)

      const gestaoCentres = centresDefault.find(c => c.value === centres)
      if (gestaoCentres.gestao !== gestao) {
        this.filtro.fields.centres = 0
      }
    },
    async filterOutrasLojas() {
      const { centres } = this.filtro.fields;
      if (centres === 0) {
        this.filtro.fields.considerarOutrasLojas = false;
      }
      await this.setIntervalo();
    }
  },
  async created() {
    await this.getAnosPeriodo();
    this.search();
  }
}
</script>
