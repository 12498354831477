import store from '@/core/store';

const { dispatch } = store;

export default {
  watch: {
    'filtro.fields.gestao': function(newValue, oldValue){
      this.filtrarLojas(newValue)
    }
  },
  created() {
    this.filtrarLojas();
  },
  methods: {
    async filtrarLojas(gestoes){
      const ArrayGestoesLojas = [gestoes, this.filtro.listas.centres];
      const lojas = await dispatch('FILTRAR_LOJAS_POR_GESTAO_UNI',ArrayGestoesLojas);
      this.filtro.listas.centresFiltradosPorGestao = lojas;
   },
  },
}