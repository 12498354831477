var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card-group",
        { staticClass: "mb-4" },
        [
          _c(
            "b-card",
            {
              staticClass: "col-md-8 p-0 mr-2",
              attrs: { "header-tag": "header", "footer-tag": "footer" }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("strong", [_vm._v(" Filtros ")])
              ]),
              _c(
                "div",
                { staticClass: "p-1 mt-2" },
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "d-md-block",
                          attrs: { sm: "12", md: "4" }
                        },
                        [
                          _c(
                            "b-button-toolbar",
                            { staticClass: "float-left" },
                            [
                              _c(
                                "b-form-radio-group",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    buttons: "",
                                    "button-variant": "outline-primary",
                                    name: "radiosBtn"
                                  },
                                  on: { change: _vm.consultaPeriodo },
                                  model: {
                                    value: _vm.filtro.fields.periodo,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filtro.fields,
                                        "periodo",
                                        $$v
                                      )
                                    },
                                    expression: "filtro.fields.periodo"
                                  }
                                },
                                [
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Diaria" }
                                    },
                                    [_vm._v("Diária")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Semanal" }
                                    },
                                    [_vm._v("Semanal")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Mensal" }
                                    },
                                    [_vm._v("Mensal")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { sm: "12", md: "8" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "custom-control custom-checkbox pt-1"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.filtro.fields.considerarOutrasLojas,
                                  expression:
                                    "filtro.fields.considerarOutrasLojas"
                                }
                              ],
                              staticClass: "custom-control-input",
                              attrs: {
                                type: "checkbox",
                                id: "cbConsiderarOutrasLojas",
                                disabled: _vm.filtro.fields.centres === 0
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.filtro.fields.considerarOutrasLojas
                                )
                                  ? _vm._i(
                                      _vm.filtro.fields.considerarOutrasLojas,
                                      null
                                    ) > -1
                                  : _vm.filtro.fields.considerarOutrasLojas
                              },
                              on: {
                                change: function($event) {
                                  var $$a =
                                      _vm.filtro.fields.considerarOutrasLojas,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.filtro.fields,
                                          "considerarOutrasLojas",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.filtro.fields,
                                          "considerarOutrasLojas",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.filtro.fields,
                                      "considerarOutrasLojas",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "custom-control-label",
                                attrs: { for: "cbConsiderarOutrasLojas" }
                              },
                              [_vm._v("Considerar vendas de outras lojas")]
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "pr-md-0 mb-1 mb-md-0",
                          attrs: {
                            sm: _vm.filtro.fields.periodo === "Diaria" ? 4 : 3
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              plain: true,
                              options: _vm.filtro.listas.gestao
                            },
                            on: { change: _vm.filterLojas },
                            model: {
                              value: _vm.filtro.fields.gestao,
                              callback: function($$v) {
                                _vm.$set(_vm.filtro.fields, "gestao", $$v)
                              },
                              expression: "filtro.fields.gestao"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "pr-md-0 mb-1 mb-md-0",
                          attrs: {
                            sm: _vm.filtro.fields.periodo === "Diaria" ? 4 : 3
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              plain: true,
                              options:
                                _vm.filtro.listas.centresFiltradosPorGestao
                            },
                            on: { change: _vm.filterOutrasLojas },
                            model: {
                              value: _vm.filtro.fields.centres,
                              callback: function($$v) {
                                _vm.$set(_vm.filtro.fields, "centres", $$v)
                              },
                              expression: "filtro.fields.centres"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.filtro.fields.periodo === "Diaria"
                        ? _c(
                            "b-col",
                            { attrs: { sm: "4" } },
                            [
                              _c("b-form-input", {
                                attrs: { plain: true, type: "date" },
                                model: {
                                  value: _vm.filtro.fields.dataDia,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filtro.fields, "dataDia", $$v)
                                  },
                                  expression: "filtro.fields.dataDia"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.filtro.fields.periodo !== "Diaria"
                        ? _c(
                            "b-col",
                            {
                              staticClass: "pr-md-0 mb-1 mb-md-0",
                              attrs: { sm: "3" }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  plain: true,
                                  options: _vm.filtro.listas.meses
                                },
                                on: { change: _vm.setIntervalo },
                                model: {
                                  value: _vm.filtro.fields.mes,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filtro.fields, "mes", $$v)
                                  },
                                  expression: "filtro.fields.mes"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.filtro.fields.periodo !== "Diaria"
                        ? _c(
                            "b-col",
                            { attrs: { sm: "3" } },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  plain: true,
                                  options: _vm.filtro.listas.anos
                                },
                                on: { change: _vm.setIntervalo },
                                model: {
                                  value: _vm.filtro.fields.ano,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filtro.fields, "ano", $$v)
                                  },
                                  expression: "filtro.fields.ano"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "pr-md-0",
                          attrs: {
                            sm: _vm.filtro.fields.periodo === "Diaria" ? 8 : 6
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              plain: true,
                              options: _vm.filtro.listas.intervalo,
                              disabled: _vm.filtro.fields.periodo !== "Semanal"
                            },
                            on: { change: _vm.setDatasIntervalo },
                            model: {
                              value: _vm.filtro.fields.intervalo,
                              callback: function($$v) {
                                _vm.$set(_vm.filtro.fields, "intervalo", $$v)
                              },
                              expression: "filtro.fields.intervalo"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "1" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "my-2 my-sm-0 text-white",
                              class: { "btn-block": _vm.$mq === "sm" },
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.search()
                                }
                              }
                            },
                            [_c("i", { staticClass: "fa fa-search" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-card",
            {
              staticClass: "col-md-4 p-0",
              attrs: { "header-tag": "header", "footer-tag": "footer" }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("strong", [_vm._v(" Layout ")])
              ]),
              _c(
                "div",
                { staticClass: "p-1" },
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "d-md-block", attrs: { sm: "12" } },
                        [
                          _c(
                            "b-button-toolbar",
                            { staticClass: "float-left" },
                            [
                              _c(
                                "b-form-radio-group",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    buttons: "",
                                    "button-variant": "outline-primary",
                                    name: "radiosBtn"
                                  },
                                  model: {
                                    value: _vm.filtro.fields.exibicao,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.filtro.fields,
                                        "exibicao",
                                        $$v
                                      )
                                    },
                                    expression: "filtro.fields.exibicao"
                                  }
                                },
                                [
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Graficos" }
                                    },
                                    [_vm._v("Gráficos")]
                                  ),
                                  _c(
                                    "b-form-radio",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { value: "Tabelas" }
                                    },
                                    [_vm._v("Tabelas")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mb-1" },
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12" } },
                        [
                          _c("b-form-select", {
                            attrs: {
                              plain: true,
                              options: _vm.filtro.listas.agrupamentos,
                              disabled: _vm.filtro.fields.centres !== 0
                            },
                            on: { change: _vm.search },
                            model: {
                              value: _vm.filtro.fields.agrupamento,
                              callback: function($$v) {
                                _vm.$set(_vm.filtro.fields, "agrupamento", $$v)
                              },
                              expression: "filtro.fields.agrupamento"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm.filtro.fields.exibicao === "Tabelas"
        ? _c(
            "div",
            _vm._l(_vm.items, function(item, index) {
              return _c(
                "b-row",
                { key: index },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", sm: "12", lg: "12" } },
                    [
                      _c(
                        "b-card",
                        { attrs: { "header-tag": "header" } },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [_c("strong", [_vm._v(_vm._s(item.name))])]
                          ),
                          _c("h6", { staticClass: "text-primary" }, [
                            _vm._v(" - Vendas")
                          ]),
                          _c("b-table", {
                            attrs: {
                              striped: "",
                              small: "",
                              responsive: "md",
                              items: item.records,
                              fields: _vm.fields.records
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "meta",
                                  fn: function(data) {
                                    return [
                                      _c("i", {
                                        staticClass: "fa fa-circle fa-lg",
                                        class: _vm.getMeta(data.item.meta)
                                      })
                                    ]
                                  }
                                },
                                {
                                  key: "vendas",
                                  fn: function(data) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              data.item.vendas,
                                              { symbol: "" }
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "bronze",
                                  fn: function(data) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              data.item.bronze /
                                                data.item.qtd_funcionarios,
                                              { symbol: "" }
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "prata",
                                  fn: function(data) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              data.item.prata /
                                                data.item.qtd_funcionarios,
                                              { symbol: "" }
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "ouro",
                                  fn: function(data) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              data.item.ouro /
                                                data.item.qtd_funcionarios,
                                              { symbol: "" }
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "diamante",
                                  fn: function(data) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(
                                              data.item.diamante /
                                                data.item.qtd_funcionarios,
                                              { symbol: "" }
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _c("h6", { staticClass: "text-primary" }, [
                            _vm._v(" - Outros Indicadores")
                          ]),
                          _c(
                            "b-table",
                            {
                              attrs: {
                                striped: "",
                                small: "",
                                responsive: "md",
                                items: item.indicadores,
                                fields: _vm.fields.indicadores,
                                "foot-clone": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "pecas",
                                    fn: function(data) {
                                      return [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("number")(data.item.pecas)
                                            ) +
                                            "\n              "
                                        ),
                                        _c("i", {
                                          staticClass: "fa",
                                          class: [
                                            data.item.pecas_arrow,
                                            data.item.pecas_arrow === "fa-minus"
                                              ? "text-warning"
                                              : data.item.pecas_arrow ===
                                                "fa-arrow-up"
                                              ? "text-success"
                                              : "text-danger"
                                          ]
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "atendimentos",
                                    fn: function(data) {
                                      return [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("number")(
                                                data.item.atendimentos
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                        _c("i", {
                                          staticClass: "fa",
                                          class: [
                                            data.item.atendimentos_arrow,
                                            data.item.atendimentos_arrow ===
                                            "fa-minus"
                                              ? "text-warning"
                                              : data.item.atendimentos_arrow ===
                                                "fa-arrow-up"
                                              ? "text-success"
                                              : "text-danger"
                                          ]
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "vp",
                                    fn: function(data) {
                                      return [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("currency")(data.item.vp, {
                                                symbol: ""
                                              })
                                            ) +
                                            "\n              "
                                        ),
                                        _c("i", {
                                          staticClass: "fa",
                                          class: [
                                            data.item.vp_arrow,
                                            data.item.vp_arrow === "fa-minus"
                                              ? "text-warning"
                                              : data.item.vp_arrow ===
                                                "fa-arrow-up"
                                              ? "text-success"
                                              : "text-danger"
                                          ]
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "va",
                                    fn: function(data) {
                                      return [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("currency")(data.item.va, {
                                                symbol: ""
                                              })
                                            ) +
                                            "\n              "
                                        ),
                                        _c("i", {
                                          staticClass: "fa",
                                          class: [
                                            data.item.va_arrow,
                                            data.item.va_arrow === "fa-minus"
                                              ? "text-warning"
                                              : data.item.va_arrow ===
                                                "fa-arrow-up"
                                              ? "text-success"
                                              : "text-danger"
                                          ]
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "pa",
                                    fn: function(data) {
                                      return [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("number")(data.item.pa)
                                            ) +
                                            "\n              "
                                        ),
                                        _c("i", {
                                          staticClass: "fa",
                                          class: [
                                            data.item.pa_arrow,
                                            data.item.pa_arrow === "fa-minus"
                                              ? "text-warning"
                                              : data.item.pa_arrow ===
                                                "fa-arrow-up"
                                              ? "text-success"
                                              : "text-danger"
                                          ]
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "clientes",
                                    fn: function(data) {
                                      return [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("number")(
                                                data.item.clientes
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                        _c("i", {
                                          staticClass: "fa",
                                          class: [
                                            data.item.clientes_arrow,
                                            data.item.clientes_arrow ===
                                            "fa-minus"
                                              ? "text-warning"
                                              : data.item.clientes_arrow ===
                                                "fa-arrow-up"
                                              ? "text-success"
                                              : "text-danger"
                                          ]
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "conversao",
                                    fn: function(data) {
                                      return [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("number")(
                                                data.item.conversao
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                        _c("i", {
                                          staticClass: "fa",
                                          class: [
                                            data.item.conversao_arrow,
                                            data.item.conversao_arrow ===
                                            "fa-minus"
                                              ? "text-warning"
                                              : data.item.conversao_arrow ===
                                                "fa-arrow-up"
                                              ? "text-success"
                                              : "text-danger"
                                          ]
                                        })
                                      ]
                                    }
                                  },
                                  {
                                    key: "descontos",
                                    fn: function(data) {
                                      return [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                data.item.descontos,
                                                { symbol: "" }
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                        _c("i", {
                                          staticClass: "fa",
                                          class: [
                                            data.item.descontos_arrow,
                                            data.item.descontos_arrow ===
                                            "fa-minus"
                                              ? "text-warning"
                                              : data.item.descontos_arrow ===
                                                "fa-arrow-up"
                                              ? "text-success"
                                              : "text-danger"
                                          ]
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("template", { slot: "FOOT_descricao" }, [
                                _vm._v(
                                  "\n              Média da " +
                                    _vm._s(item.name) +
                                    "\n            "
                                )
                              ]),
                              _c("template", { slot: "FOOT_pecas" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm._f("number")(item.media.pecas)) +
                                    "\n            "
                                )
                              ]),
                              _c("template", { slot: "FOOT_atendimentos" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("number")(item.media.atendimentos)
                                    ) +
                                    "\n            "
                                )
                              ]),
                              _c("template", { slot: "FOOT_vp" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("currency")(item.media.vp, {
                                        symbol: ""
                                      })
                                    ) +
                                    "\n            "
                                )
                              ]),
                              _c("template", { slot: "FOOT_va" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("currency")(item.media.va, {
                                        symbol: ""
                                      })
                                    ) +
                                    "\n            "
                                )
                              ]),
                              _c("template", { slot: "FOOT_pa" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm._f("number")(item.media.pa)) +
                                    "\n            "
                                )
                              ]),
                              _c("template", { slot: "FOOT_conversao" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("number")(item.media.conversao)
                                    ) +
                                    "\n            "
                                )
                              ]),
                              _c("template", { slot: "FOOT_clientes" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("number")(item.media.clientes)
                                    ) +
                                    "\n            "
                                )
                              ]),
                              _c("template", { slot: "FOOT_descontos" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("currency")(item.media.descontos, {
                                        symbol: ""
                                      })
                                    ) +
                                    "\n            "
                                )
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.filtro.fields.exibicao === "Graficos"
        ? _c(
            "div",
            [
              _vm.exibirMetaAtingida
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "12", lg: "4" } },
                        [
                          _c(
                            "b-card",
                            { attrs: { "no-body": true } },
                            [
                              _c("b-card-body", { staticClass: "clearfix" }, [
                                _c("i", {
                                  staticClass:
                                    "icon-up p-3 font-2xl mr-3 float-left float-up",
                                  class:
                                    "meta-" +
                                    (_vm.metaAtingida.icon || "nao-atingida")
                                }),
                                _c(
                                  "div",
                                  { staticClass: "text-right mb-0 h5" },
                                  [_vm._v("Meta Atingida")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "ml-auto text-center text-muted mt-5"
                                  },
                                  [
                                    _c(
                                      "h2",
                                      {
                                        staticClass: "ml-auto font-weight-bold"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("currency")(_vm.valorVendas)
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "ml-auto text-center mb-5",
                                    class:
                                      _vm.metaAtingida.diferenca < 0
                                        ? "text-danger"
                                        : "text-success"
                                  },
                                  [
                                    _c("h5", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.metaAtingida.diferenca < 0
                                            ? "Falta"
                                            : "Passou"
                                        ) +
                                          " " +
                                          _vm._s(
                                            _vm._f("currency")(
                                              Math.abs(
                                                _vm.metaAtingida.diferenca
                                              )
                                            )
                                          )
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", sm: "12", lg: "8" } },
                        [
                          _c(
                            "b-card",
                            { attrs: { "no-body": true } },
                            [
                              _c(
                                "b-card-body",
                                { staticClass: "clearfix" },
                                [
                                  _c("i", {
                                    staticClass:
                                      "icon-up vendas p-3 font-2xl mr-3 float-left float-up"
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "text-right mb-0 h5" },
                                    [_vm._v("Metas")]
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-4" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            lg: "6"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex" },
                                            [
                                              _vm.loaded
                                                ? _c("gauge", {
                                                    staticClass: "w-100",
                                                    attrs: {
                                                      chartdata:
                                                        _vm.charts.MetaAtingida,
                                                      height: 134,
                                                      chartId: "chart-gauge-01"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pt-4",
                                          attrs: {
                                            cols: "12",
                                            sm: "6",
                                            lg: "6"
                                          }
                                        },
                                        [
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "span",
                                              { staticClass: "text-muted" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "badge badge-meta-bronze",
                                                    staticStyle: {
                                                      "vertical-align":
                                                        "middle",
                                                      "margin-bottom": "2px"
                                                    }
                                                  },
                                                  [_vm._v("  ")]
                                                ),
                                                _vm._v(
                                                  " Meta Bronze\n                  "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "ml-auto" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.valorMetas.bronze
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ]),
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "span",
                                              { staticClass: "text-muted" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "badge badge-meta-prata",
                                                    staticStyle: {
                                                      "vertical-align":
                                                        "middle",
                                                      "margin-bottom": "2px"
                                                    }
                                                  },
                                                  [_vm._v("  ")]
                                                ),
                                                _vm._v(
                                                  " Meta Prata\n                  "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "ml-auto" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.valorMetas.prata
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ]),
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "span",
                                              { staticClass: "text-muted" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "badge badge-meta-ouro",
                                                    staticStyle: {
                                                      "vertical-align":
                                                        "middle",
                                                      "margin-bottom": "2px"
                                                    }
                                                  },
                                                  [_vm._v("  ")]
                                                ),
                                                _vm._v(
                                                  " Meta Ouro\n                  "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "ml-auto" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.valorMetas.ouro
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ]),
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c(
                                              "span",
                                              { staticClass: "text-muted" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "badge badge-meta-diamante",
                                                    staticStyle: {
                                                      "vertical-align":
                                                        "middle",
                                                      "margin-bottom": "2px"
                                                    }
                                                  },
                                                  [_vm._v("  ")]
                                                ),
                                                _vm._v(
                                                  " Meta Diamante\n                  "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "ml-auto" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.valorMetas.diamante
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.items, function(item, index) {
                return _c(
                  "b-card",
                  { key: index },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "font-weight-bold",
                        attrs: { slot: "header" },
                        slot: "header"
                      },
                      [_vm._v(_vm._s(item.name))]
                    ),
                    _vm.loaded && _vm.displayChart(item, "Gestoes")
                      ? _c("horizontal-bar", {
                          attrs: {
                            chartdata: item.charts.Gestoes,
                            displayLegend: false,
                            moneyFormat: true,
                            height:
                              (item.charts.Gestoes.datasets[0].data.length +
                                10) *
                              10,
                            chartId: "chart-bar-01"
                          }
                        })
                      : _vm._e(),
                    !_vm.displayChart(item, "Gestoes")
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "alert alert-secondary text-center mb-0"
                          },
                          [
                            _vm._v(
                              "\n        Nenhum resultado encontrado.\n      "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }