<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: {
    chartdata: {
      type: Object,
      default: null
    }
  },
  mounted () {
    this.renderChart(this.chartdata, {
      circumference: Math.PI,
      rotation: 1.0 * Math.PI,
      legend: {
        display: false,
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: true,
        callbacks: {
          label: ((tooltipItem, data) => {
            const sum = data.datasets[tooltipItem.datasetIndex].data.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr))
            const valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            const percent = (100 * valor / (sum === 0 ? 1 : sum))
            const label = data.labels[tooltipItem.index];

            return `${label}: R$ ${Number(valor || 0).toLocaleString('pt-BR', {minimumFractionDigits: 2})} (${percent.toLocaleString('pt-BR', {minimumFractionDigits: 0, maximumFractionDigits: 2})}%)`;
          })
        }
      }
    })
  }
}
</script>
