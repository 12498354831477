import slugify from 'slugify';
import leftPad from 'left-pad';

export const stringToHex = function (string) {
  const {length} = string;
  let hex = '';

  for (let i = 0; i < length; i += 1) {
    hex += string.charCodeAt(i).toString(16) + ' ';
  }

  hex = hex.trim();

  return hex;
};

export const hexToString = function (hex) {
  const hexArray = hex.split(' ');
  const {length} = hexArray;

  let string = '';

  for (let i = 0; i < length; i += 1) {
    string += String.fromCharCode(parseInt(hexArray[i], 16));
  }

  return string;
};

export const asciiToHex = function (string) {
  const array = [];

  for (let i = 0, l = string.length; i < l; i++) {
    const hex = Number(string.charCodeAt(i)).toString(16);
    array.push(hex);
  }

  return array.join('').match(/.{2}/g).join(' ');
};

export const hexToASCII = function (hexx) {
  const hex = hexx.toString();
  let str = '';

  for (let i = 0; i < hex.length; i += 2) {
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  }

  return str;
};

export const removeSpaces = function (string) {
  return string.toString().replace(/\s/g, '').trim();
};

export const cleanup = function (string) {
  return slugify(String(string), {
    replacement: '_',
    lower: true
  });
};

export const formatPhone = function (phone) {
  phone = String(phone);
  phone = phone.replace(/\D/g, '');
  phone = phone.replace(/(\d{2})(\d)/, '($1) $2');
  phone = phone.replace(/(\d{2})(\d{4,5})/, '($1) $2');
  phone = phone.replace(/(\d{4,5})(\d{4})$/, '$1-$2');
  return phone;
};

export const diaSemana = function (dow) {
  switch (Number(dow)) {
    case 0: return 'Domingo';
    case 1: return 'Segunda-Feira';
    case 2: return 'Terça-Feira';
    case 3: return 'Quarta-Feira';
    case 4: return 'Quinta-Feira';
    case 5: return 'Sexta-Feira';
    case 6: return 'Sábado';
  }
};

export const mes = function (mes) {
  switch (Number(mes)) {
    case 1: return 'Janeiro';
    case 2: return 'Fevereiro';
    case 3: return 'Março';
    case 4: return 'Abril';
    case 5: return 'Maio';
    case 6: return 'Junho';
    case 7: return 'Julho';
    case 8: return 'Agosto';
    case 9: return 'Setembro';
    case 10: return 'Outubro';
    case 11: return 'Novembro';
    case 12: return 'Dezembro';
  }
}